.form-title{
    margin: 0 1rem 1rem;
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: 500;
}
.contactus-body{
    display: flex;
    justify-content: space-between;
}

.form-wrapper{
    margin: 2rem 2rem 2rem 0rem;
    border: 2px;
    width: 100%;
    height: 600px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.contactus-image{
    margin: 2rem 0rem 2rem 2rem;
}
.map-container{
    padding: 0 1rem 1rem;
}


@media screen and (max-width:992px){
    div.contactus-body{
        flex-direction: column-reverse;
    }
} 

@media screen and (max-width:992px){
    div.contactus-image{
        display: none;
    }
} 

