.navbar{
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    background-color: white;
    overflow: hidden;
}

.nav-link{
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    padding:1rem;
    margin: 0 1rem;
}

.active, .nav-link:hover{
    color: rgb(209, 208, 208);
    border-bottom: 2px solid #336d57;
}

h3{
    text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
