.icons-container{
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.bi{
    color: rgba(0,0,0,.55);
    padding: 0 1rem;
    font-style: normal;
}

.bi:hover{
    color: #495057;
}

.address{
  display: flex;
  font-size: large;
  padding-top: 1rem;
  justify-content: center;
  text-align: center;
  color: rgba(0,0,0,.55);
  margin-bottom: 0;
  line-height: 1.6;
}

.contact{
    display: flex;  
    font-size: large;  
    flex-direction: column;
    justify-content: center;
    text-align: center;
    line-height: 1.6;
    padding: 0  1rem 1rem;
}