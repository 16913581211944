.services{
    display: flex;
    margin: 0  1rem;
    padding: 0 1rem;
    justify-content: space-between;
    flex-grow: 1;
}

.services-header{
    padding-bottom: 0px;
    color:rgba(0,0,0,.55);
}

.lists{
    margin: 0 0 2rem 2rem;
    padding: 1rem;
    font-size: larger;
    font-weight: 400;
    color:rgba(0,0,0,.55);
    list-style-type: circle;
}

.service-image{
    max-width: 100%;
}

.service-wrapper{
    margin: 2rem;
    height: 600px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}