.footer{
background-color: #fbfbfb;
}

.footer-menu {
    list-style:none;
    display: flex;
    justify-content: center;
    margin-top: 1rem !important;
    
  }
  .footer-menu .menu-item{
    display:inline-block; 
    padding: 0 30px; 
    position:relative;

  }
  .footer-menu .menu-item:not(:last-child)::after{
    content:""; 
    border:1px solid #e2e2e2;
    border-width: 2px 2px 0 0;
    position:absolute; 
    right:-3px; 
    top:0; 
    height:100%;
  }

  @media (max-width: 768px) {
    .footer-menu {
      flex-direction: column;
    }
  }


.link{
    text-decoration: none !important;
    color: rgba(0,0,0,.55);
    font-size: large;
    font-weight:400;
}

.link:hover{
    color: teal;
    
}

.hr{
    border-top: 1px solid teal;
}

.copyright{
    text-align: center;
    color:rgba(0,0,0,.55);
    padding-bottom: 1rem;
}