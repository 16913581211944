.image-wrapper{
    margin: 1rem;
    padding: 1rem;    
}

.value-img{
    max-width: 100%;
    height: auto; 
    object-fit: fill;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;}

h4{
    color: #336d57;
    margin-bottom: 0 !important;
    margin-left: 1rem;
    padding: 1rem;
    line-height: 1 !important;
}

.description{ font-size: larger;
    margin-left: 1rem;
    padding-left: 1rem;
    font-weight: 400;
    color:rgba(0,0,0,.55);
    word-wrap: break-word;
    text-align: start;}