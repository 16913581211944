.banner{
  width: 100vw;
  height: 80vh;
  object-position: bottom left;
  object-fit: cover;
}

.other-slides{
  width: 100vw;
  height: 80vh;
  object-position: top left;
  object-fit: cover;
}

h3{
  text-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.carousel-item{
  border-bottom: 0px ;
}