.wrapper{
    padding: 3rem;
}
.text-body{
    display: flex;
    align-content: flex-start;
}

h2{
    color: #336d57;
    margin:  1rem;
    padding: 1rem;
}

.paragraph{
    padding: 1rem;
    margin: 1rem;
    font-size: larger;
    font-weight: 400;
    color:rgba(0,0,0,.55);
    word-break: break-word;
    white-space: pre-line;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.security-images{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media (max-width: 800px) {
    .text-body{
      flex-direction: column;
    }
  }
